export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SEDOT_TOKPED = 'SEDOT_TOKPED';
export const SEDOT_BUKA = 'SEDOT_BUKA';
export const INPUT_WP = 'INPUT_WP';
export const EDIT_WP = 'EDIT_WP';
export const FETCH_WP  = 'FETCH_WP';
export const DELETE_WP = 'DELETE_WP';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const ADD_WP = 'ADD_WP';
export const REMOVE_WP = 'REMOVE_WP';
export const RENDER_KAPAL = 'RENDER_KAPAL';
export const UNRENDER_KAPAL = 'UNRENDER_KAPAL';
export const RENDER_LSPOP = 'RENDER_LSPOP';
export const UNRENDER_LSPOP = 'UNRENDER_LSPOP';
export const RENDER_TANGKAPAN = 'RENDER_TANGKAPAN';
export const UNRENDER_TANGKAPAN = 'UNRENDER_TANGKAPAN';
export const ADD_TANGKAPAN = 'ADD_TANGKAPAN';
export const INPUT_TANGKAPAN = 'INPUT_TANGKAPAN';
export const DELETE_TANGKAPAN = 'DELETE_TANGKAPAN';
export const INPUT_KAPAL = 'INPUT_KAPAL';
export const DELETE_KAPAL = 'DELETE_KAPAL';